<template>
  <v-dialog v-model="dialog" persistent width="800">
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        color="green"
        overlap
        :content="users.length"
        :value="users.length"
        :style="badgeFloat"
      >
        <v-btn
          elevation="5"
          class="amber lighten-1 msaBlue--text"
          large
          v-bind="attrs"
          v-on="on"
        >
          <v-icon> mdi-file-document-multiple-outline </v-icon>
          Selected Users
        </v-btn>
      </v-badge>
    </template>
    <v-card>
      <v-card-title class="msaBlue white--text">
        <v-row>
          <v-col>Mass Management</v-col>
          <v-col class="shrink">
            <v-btn icon @click="close()">
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="pt-4">
        <v-row>
          <v-col>
            <v-card>
              <v-simple-table>
                <template v-slot:default>
                  <thead class="lightBg">
                    <tr>
                      <th class="text-left">Selected Users</th>
                      <th class="text-center">Remove From Selection</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in users" :key="item.id">
                      <td>
                        {{ item.fullname }}
                      </td>
                      <td align="center">
                        <v-btn icon color="red" @click="unselect(item)">
                          <v-icon> mdi-minus-circle-outline </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn class="red" dark @click="detachAll()">
          <v-icon>mdi-tag-off-outline</v-icon>
          detach all
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text dark color="red" @click="cancel">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'GroupedUsersMassManage',
  data() {
    return {
      dialog: false,
      badgeFloat: `position: fixed; bottom: 20px; z-index: 7;`,
    };
  },
  props: {
    users: {
      type: Array,
    },
  },
  methods: {
    close() {
      this.dialog = false;
    },
    cancel() {
      this.$emit('unselectAll');
      this.dialog = false;
    },
    detachAll() {
      this.$root.showMessage(
        'Confirm',
        'Are you sure you want to detach these users?',
        () => confirm(),
        () => false,
        'Confirm',
        'Cancel',
      );

      const confirm = () => {
        this.$emit('detachAll');
        this.dialog = false;
      };
    },
    unselect(item) {
      this.$emit('onUnselect', item);
      if (this.users.length == 0) {
        this.close();
      }
    },
  },
};
</script>
